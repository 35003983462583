import { freqSymbols } from "@/calc/acoustic-constants";
import {
  getRTDeviationRules,
  dinTargetReverberationTime,
  isCategoryB,
  SCENARIO_SYMBOLS,
  dinCatBMinAVRatios,
  dinCatBAVRatiosPassed
} from "@/calc/din-requirements";
import { OctoFrequential, WALL_SYMBOLS, WALL_TITLES } from "@/calc/room";
import { DetailedResult } from "@/calc/sound-pressure-summed";
import { CalculationState, store } from "@/state/state";
import { PageSizes, PDFDocument, StandardFonts } from "pdf-lib";
import { maxTimeWindow, OctoValuePlot } from "../diagram";
import { downloadBlob } from "../file-download";
import { drawOctoFrequentialDiagram } from "./pdf-diagram";
import { drawHeading } from "./pdf-heading";
import { drawParagraph } from "./pdf-paragraph";
import {
  drawOctoFrequentialTableLine,
  drawHexaFrequentialTableRow
} from "./pdf-table";
import { PDFWriteHead } from "./pdf-write-head";
import fontkit from "@pdf-lib/fontkit";
import fraunhoferNormalFontURL from "@/assets/fonts/Helvetica.ttf";
import fraunhoferBoldFontURL from "@/assets/fonts/Helvetica-Bold.ttf";
//import fraunhoferNormalFontURL from "@/assets/fonts/f901b503-9104-414a-a856-af9bcc802b5c.ttf";
//import fraunhoferBoldFontURL from "@/assets/fonts/efe9def0-77d1-4c28-8fd2-371236a3c8ed.ttf";
import { drawIBPLogo } from "./pdf-logo";
import { drawWall } from "./pdf-room-preview";
import {
  ABSORPTION_DATABASE,
  SCATTERING_DATABASE
} from "@/res/materials-absorption";
import {
  processedAbsorptionAssignments,
  processedScatteringAssignments,
  wallAverage
} from "@/calc/area-assignment";
import { wallsTotalArea } from "@/calc/sound-pressure-diffuse";
import { FURNITURE_DATABASE } from "@/res/furniture-surfaces";

export async function createPDFReport(
  calc: CalculationState,
  res: DetailedResult
): Promise<PDFDocument> {
  const pageSize = PageSizes.A4;
  const pdfDoc = await PDFDocument.create();
  pdfDoc.registerFontkit(fontkit);
  const normalFontBytes = await fetch(fraunhoferNormalFontURL)
    .then(res => res.arrayBuffer())
    .catch(() => StandardFonts.Helvetica);
  const boldFontBytes = await fetch(fraunhoferBoldFontURL)
    .then(res => res.arrayBuffer())
    .catch(() => StandardFonts.HelveticaBold);
  const normalFont = await pdfDoc.embedFont(normalFontBytes);
  const boldFont = await pdfDoc.embedFont(boldFontBytes);

  const writeHead = new PDFWriteHead(pdfDoc, pageSize, normalFont, boldFont);
  pdfDoc.setTitle("Reverberation Report");
  pdfDoc.setAuthor("Fraunhofer IBP");
  pdfDoc.setSubject("A calculation using Zhou et al. 2021");
  pdfDoc.setKeywords([
    "reverberation time",
    "decay",
    "room acoustics",
    "Sabine",
    "Eyring",
    "Zhou",
    "Fraunhofer IBP"
  ]);
  pdfDoc.setProducer("Room Acoustics Web App");
  pdfDoc.setCreator("pdf-lib (https://github.com/Hopding/pdf-lib)");
  pdfDoc.setCreationDate(new Date());
  pdfDoc.setModificationDate(new Date());
  drawIBPLogo(writeHead);
  writeHead.goDown(20);
  drawHeading(writeHead, "Room Reverberation Report", 1);
  drawParagraph(
    writeHead,
    `This calculation was performed with reverberate. reverberate is based on an innovative calculation model by the acoustics team at the Fraunhofer IBP Institute for Building Physics. It predicts the reverberation time in rectangular rooms much more precise than classical calculation methods.`
  );
  writeHead.goDown(30);
  drawHeading(writeHead, "Room Parameters", 2);
  drawParagraph(
    writeHead,
    `Room Name: ${store.roomName}\n` + // Include room name in the PDF
      `Dimensions: ${calc.roomSize[0]} m × ${calc.roomSize[1]} m × ${calc.roomSize[2]} m\n` +
      `Ambient atmospheric temperature: ${calc.atmosphere.T - 273.15} °C\n` +
      `Relative humidity:  ${calc.atmosphere.hum_rel} %\n` +
      `Ambient atmospheric pressure:  ${calc.atmosphere.pa} hPa`
  );
  drawHeading(writeHead, "Reverberation Time Per Frequency", 2);
  drawParagraph(
    writeHead,
    "The following diagram shows the reverberation time for different frequencies as calculated with Zhou et al. 2021 compared to those predicted with C. Sabine."
  );
  const octoFreqPlotZhou: OctoValuePlot = {
    values: res.octoFrequential.map(
      fRes => fRes.reverberationTimeZhou
    ) as OctoFrequential<number>,
    label: "reverberate (Zhou et al.)",
    drawingStyle: "line",
    bullet: "circle",
    dominant: true
  };
  const octoFreqPlotSabine: OctoValuePlot = {
    values: res.octoFrequential.map(
      fRes => fRes.reverberationTimeSabine
    ) as OctoFrequential<number>,
    label: "classical method (C. Sabine)",
    drawingStyle: "line",
    bullet: "triangle",
    dominant: true
  };
  const plotYMax = maxTimeWindow([
    ...octoFreqPlotZhou.values,
    ...octoFreqPlotSabine.values
  ]);
  const dinCorridor = getRTDeviationRules(calc.dinScenario);
  const dinTargetRT = dinTargetReverberationTime(res.V, calc.dinScenario);
  drawOctoFrequentialDiagram(
    writeHead,
    writeHead.innerWidth,
    [octoFreqPlotZhou, octoFreqPlotSabine],
    plotYMax,
    dinTargetRT,
    dinCorridor,
    "Reverberation Time [s]"
  );
  writeHead.nextPage();
  drawIBPLogo(writeHead);
  drawHeading(writeHead, "Reverberation Time Per Frequency", 2);
  drawHexaFrequentialTableRow(
    writeHead,
    writeHead.innerWidth,
    freqSymbols.map(t => `${t}Hz`) as OctoFrequential<string>,
    null
  );
  drawOctoFrequentialTableLine(writeHead, writeHead.innerWidth);
  drawHexaFrequentialTableRow(
    writeHead,
    writeHead.innerWidth,
    octoFreqPlotZhou.values.map(val =>
      val.toFixed(2)
    ) as OctoFrequential<string>,
    octoFreqPlotZhou.label + " [s]"
  );
  drawHexaFrequentialTableRow(
    writeHead,
    writeHead.innerWidth,
    octoFreqPlotSabine.values.map(val =>
      val.toFixed(2)
    ) as OctoFrequential<string>,
    octoFreqPlotSabine.label + " [s]"
  );
  if (dinTargetRT !== null && dinCorridor !== null) {
    drawOctoFrequentialTableLine(writeHead, writeHead.innerWidth);
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      Array.from({ length: 8 }).map(() =>
        dinTargetRT.toFixed(2)
      ) as OctoFrequential<string>,
      "DIN 18041 target RT [s]"
    );
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      dinCorridor.map(rule =>
        (rule.minDeviation * dinTargetRT).toFixed(2)
      ) as OctoFrequential<string>,
      "DIN 18041 shortest tolerated RT [s]"
    );
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      dinCorridor.map(rule =>
        (rule.maxDeviation * dinTargetRT).toFixed(2)
      ) as OctoFrequential<string>,
      "DIN 18041 longest tolerated RT [s]"
    );
  }
  writeHead.goDown(writeHead.BASE_FONT_SIZE);
  drawHeading(writeHead, "Absorption Coefficients ", 2);
  drawHexaFrequentialTableRow(
    writeHead,
    writeHead.innerWidth,
    freqSymbols.map(t => `${t}Hz`) as OctoFrequential<string>,
    null
  );
  drawOctoFrequentialTableLine(writeHead, writeHead.innerWidth);
  ["x", "y", "z"].forEach((dim, i) => {
    const alpha_i = res.octoFrequential.map(
      fRes => fRes.triDimensional[i].alpha_i
    );
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      alpha_i.map(val => val.toFixed(2)) as OctoFrequential<string>,
      `alpha_${dim} (dimensional area weighted) [α]`
    );
  });
  const alpha_f = res.octoFrequential.map(
    fRes => fRes.alpha_s
  ) as OctoFrequential<number>;
  drawHexaFrequentialTableRow(
    writeHead,
    writeHead.innerWidth,
    alpha_f.map(val => val.toFixed(2)) as OctoFrequential<string>,
    "alpha_s (total area weighted) [α]"
  );
  writeHead.goDown(writeHead.BASE_FONT_SIZE);
  drawHeading(writeHead, "Scattering Coefficients", 2);
  drawHexaFrequentialTableRow(
    writeHead,
    writeHead.innerWidth,
    freqSymbols.map(t => `${t}Hz`) as OctoFrequential<string>,
    null
  );
  drawOctoFrequentialTableLine(writeHead, writeHead.innerWidth);
  ["x", "y", "z"].forEach((dim, i) => {
    const sc_i = res.octoFrequential.map(fRes => fRes.triDimensional[i].SC_i);
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      sc_i.map(val => val.toFixed(2)) as OctoFrequential<string>,
      `Sc_${dim} (dimensional area weighted) [sc]`
    );
  });
  writeHead.nextPage();
  drawIBPLogo(writeHead);
  if (isCategoryB(calc.dinScenario)) {
    const scen = SCENARIO_SYMBOLS[calc.dinScenario];
    const AVRatios = res.octoFrequential.map(
      fResult => fResult.AV
    ) as OctoFrequential<number>;
    const minAVRatios = dinCatBMinAVRatios(calc.roomSize[2], calc.dinScenario);
    const AVRatiosPassed = dinCatBAVRatiosPassed(AVRatios, minAVRatios);
    drawHeading(
      writeHead,
      `A\u202F/\u202FV Ratio Per Frequency for Scenario ${scen}`,
      2
    );
    drawParagraph(
      writeHead,
      "For rooms in category B, DIN 18041 does not specify requirements" +
        "based on the reverberation time, but rather on the room's height" +
        "and the ratio between the equivalent sound absorption area and the" +
        "room volume (A\u202F/\u202FV ratio). The following table" +
        "shows the calculated A\u202F/\u202FV ratio for the" +
        "configured room as well as the minimal allowed value for scenario" +
        `${scen} as described by DIN 18041:`
    );
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      freqSymbols.map(t => `${t}Hz`) as OctoFrequential<string>,
      null
    );
    drawOctoFrequentialTableLine(writeHead, writeHead.innerWidth);
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      AVRatios.map(val => val.toFixed(3)) as OctoFrequential<string>,
      "A\u202F/\u202FV[m^2/m^3]"
    );
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      minAVRatios.map(val =>
        val === null ? "*" : val.toFixed(3)
      ) as OctoFrequential<string>,
      "Min. required A\u202F/\u202FV[m^2/m^3]"
    );
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      AVRatiosPassed.map(val =>
        val === null ? "*" : val ? "yes" : "no"
      ) as OctoFrequential<string>,
      "Compliance"
    );
    writeHead.goDown(writeHead.BASE_FONT_SIZE);
    drawParagraph(
      writeHead,
      "* The DIN 18041 requirements for the A\u202F/\u202FV ratio of rooms in " +
        "category B only apply to the range from 250 to 2000 Hz. For " +
        "scenario B1, the A\u202F/\u202FV ratio does not have to meet any minimum " +
        "requirements."
    );
    writeHead.goDown(writeHead.BASE_FONT_SIZE);
    writeHead.nextPage();
  }

  drawWallDetails(writeHead, calc, "absorption");
  writeHead.nextPage();
  drawIBPLogo(writeHead);
  drawWallDetails(writeHead, calc, "scattering");
  // Conditionally add furniture details page
  if (calc.furniture.length > 0) {
    writeHead.nextPage();
    drawIBPLogo(writeHead);
    drawFurnitureDetails(writeHead, calc);
  }
  let pages = pdfDoc.getPages();
  pages.forEach((page, index) => {
    page.drawText(index + 1 + " / " + pages.length, {
      x: 540,
      y: 30,
      size: writeHead.BASE_FONT_SIZE
    });
  });
  return pdfDoc;
}
export async function createSimplePDFReport(
  calc: CalculationState,
  res: DetailedResult
): Promise<PDFDocument> {
  const pageSize = PageSizes.A4;
  const pdfDoc = await PDFDocument.create();
  pdfDoc.registerFontkit(fontkit);
  const normalFontBytes = await fetch(fraunhoferNormalFontURL)
    .then(res => res.arrayBuffer())
    .catch(() => StandardFonts.Helvetica);
  const boldFontBytes = await fetch(fraunhoferBoldFontURL)
    .then(res => res.arrayBuffer())
    .catch(() => StandardFonts.HelveticaBold);
  const normalFont = await pdfDoc.embedFont(normalFontBytes);
  const boldFont = await pdfDoc.embedFont(boldFontBytes);

  const writeHead = new PDFWriteHead(pdfDoc, pageSize, normalFont, boldFont);
  pdfDoc.setTitle("Simple Reverberation Report");
  pdfDoc.setAuthor("Fraunhofer IBP");
  pdfDoc.setSubject("A simple calculation using Zhou et al. 2021");
  pdfDoc.setKeywords([
    "reverberation time",
    "decay",
    "room acoustics",
    "Sabine",
    "Eyring",
    "Zhou",
    "Fraunhofer IBP"
  ]);
  pdfDoc.setProducer("Room Acoustics Web App");
  pdfDoc.setCreator("pdf-lib (https://github.com/Hopding/pdf-lib)");
  pdfDoc.setCreationDate(new Date());
  pdfDoc.setModificationDate(new Date());
  drawIBPLogo(writeHead);
  writeHead.goDown(20);
  drawHeading(writeHead, "Simple Room Reverberation Report", 1);
  drawParagraph(
    writeHead,
    `This calculation was performed with reverberate. reverberate is based on an
innovative calculation model by the acoustics team at the Fraunhofer IBP Institute for
Building Physics. It predicts the reverberation time in rectangular rooms much more
precise than classical calculation methods`
  );
  writeHead.goDown(20);
  drawHeading(writeHead, "Room Parameters", 2);
  drawParagraph(
    writeHead,
    `Room Name: ${store.roomName}\n` + // Include room name in the PDF
      `Dimensions: ${calc.roomSize[0]} m × ${calc.roomSize[1]} m × ${calc.roomSize[2]} m\n` +
      `Ambient atmospheric temperature: ${calc.atmosphere.T - 273.15} °C\n` +
      `Relative humidity:  ${calc.atmosphere.hum_rel} %\n` +
      `Ambient atmospheric pressure:  ${calc.atmosphere.pa} hPa`
  );
  writeHead.goDown(20);
  drawHeading(writeHead, "Reverberation Time Per Frequency", 2);
  drawParagraph(
    writeHead,
    "The following diagram shows the reverberation time for different frequencies as calculated with Zhou et al. 2021 compared to those predicted with C. Sabine."
  );
  const octoFreqPlotZhou: OctoValuePlot = {
    values: res.octoFrequential.map(
      fRes => fRes.reverberationTimeZhou
    ) as OctoFrequential<number>,
    label: "reverberate (Zhou et al.)",
    drawingStyle: "line",
    bullet: "circle",
    dominant: true
  };
  const octoFreqPlotSabine: OctoValuePlot = {
    values: res.octoFrequential.map(
      fRes => fRes.reverberationTimeSabine
    ) as OctoFrequential<number>,
    label: "classical method (C. Sabine)",
    drawingStyle: "line",
    bullet: "triangle",
    dominant: true
  };
  const plotYMax = maxTimeWindow([
    ...octoFreqPlotZhou.values,
    ...octoFreqPlotSabine.values
  ]);
  const dinCorridor = getRTDeviationRules(calc.dinScenario);
  const dinTargetRT = dinTargetReverberationTime(res.V, calc.dinScenario);
  drawOctoFrequentialDiagram(
    writeHead,
    writeHead.innerWidth,
    [octoFreqPlotZhou, octoFreqPlotSabine],
    plotYMax,
    dinTargetRT,
    dinCorridor,
    "Reverberation Time [s]"
  );
  writeHead.nextPage();
  drawIBPLogo(writeHead);
  drawHeading(writeHead, "Reverberation Time Per Frequency", 2);
  drawHexaFrequentialTableRow(
    writeHead,
    writeHead.innerWidth,
    freqSymbols.map(t => `${t}Hz`) as OctoFrequential<string>,
    null
  );
  drawOctoFrequentialTableLine(writeHead, writeHead.innerWidth);
  drawHexaFrequentialTableRow(
    writeHead,
    writeHead.innerWidth,
    octoFreqPlotZhou.values.map(val =>
      val.toFixed(2)
    ) as OctoFrequential<string>,
    octoFreqPlotZhou.label + " [s]"
  );
  drawHexaFrequentialTableRow(
    writeHead,
    writeHead.innerWidth,
    octoFreqPlotSabine.values.map(val =>
      val.toFixed(2)
    ) as OctoFrequential<string>,
    octoFreqPlotSabine.label + " [s]"
  );
  if (dinTargetRT !== null && dinCorridor !== null) {
    drawOctoFrequentialTableLine(writeHead, writeHead.innerWidth);
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      Array.from({ length: 8 }).map(() =>
        dinTargetRT.toFixed(2)
      ) as OctoFrequential<string>,
      "DIN 18041 target RT [s]"
    );
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      dinCorridor.map(rule =>
        (rule.minDeviation * dinTargetRT).toFixed(2)
      ) as OctoFrequential<string>,
      "DIN 18041 shortest tolerated RT [s]"
    );
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      dinCorridor.map(rule =>
        (rule.maxDeviation * dinTargetRT).toFixed(2)
      ) as OctoFrequential<string>,
      "DIN 18041 longest tolerated RT [s]"
    );
  }
  writeHead.goDown(writeHead.BASE_FONT_SIZE);
  drawHeading(writeHead, "Absorption Coefficients ", 2);
  drawHexaFrequentialTableRow(
    writeHead,
    writeHead.innerWidth,
    freqSymbols.map(t => `${t}Hz`) as OctoFrequential<string>,
    null
  );
  drawOctoFrequentialTableLine(writeHead, writeHead.innerWidth);
  ["x", "y", "z"].forEach((dim, i) => {
    const alpha_i = res.octoFrequential.map(
      fRes => fRes.triDimensional[i].alpha_i
    );
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      alpha_i.map(val => val.toFixed(2)) as OctoFrequential<string>,
      `alpha_${dim} (dimensional area weighted) [α]`
    );
  });
  const alpha_f = res.octoFrequential.map(
    fRes => fRes.alpha_s
  ) as OctoFrequential<number>;
  drawHexaFrequentialTableRow(
    writeHead,
    writeHead.innerWidth,
    alpha_f.map(val => val.toFixed(2)) as OctoFrequential<string>,
    "alpha_s (total area weighted) [α]"
  );
  writeHead.goDown(writeHead.BASE_FONT_SIZE);
  drawHeading(writeHead, "Scattering Coefficients", 2);
  drawHexaFrequentialTableRow(
    writeHead,
    writeHead.innerWidth,
    freqSymbols.map(t => `${t}Hz`) as OctoFrequential<string>,
    null
  );
  drawOctoFrequentialTableLine(writeHead, writeHead.innerWidth);
  ["x", "y", "z"].forEach((dim, i) => {
    const sc_i = res.octoFrequential.map(fRes => fRes.triDimensional[i].SC_i);
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      sc_i.map(val => val.toFixed(2)) as OctoFrequential<string>,
      `Sc_${dim} (dimensional area weighted) [sc]`
    );
  });
  let pages = pdfDoc.getPages();
  pages.forEach((page, index) => {
    page.drawText(index + 1 + " / " + pages.length, {
      x: 540,
      y: 30,
      size: writeHead.BASE_FONT_SIZE
    });
  });
  return pdfDoc;
}
function drawWallDetails(
  writeHead: PDFWriteHead,
  calc: CalculationState,
  which: "absorption" | "scattering"
) {
  const isAbsorption = which === "absorption";
  drawHeading(
    writeHead,
    `Detailed Wall ${isAbsorption ? "Absorption" : "Scattering"}`,
    2
  );
  const totalWallAreas = wallsTotalArea(calc.roomSize);
  calc.roomWalls.forEach((wall, wIdx) => {
    if (wIdx === 3) {
      writeHead.nextPage();
      drawIBPLogo(writeHead);
    }
    drawWall(writeHead, wIdx);
    drawHeading(
      writeHead,
      `${WALL_TITLES[wIdx]} (${WALL_SYMBOLS[wIdx]})` +
        ` ${isAbsorption ? "Absorption" : "Scattering"}`,
      3
    );
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      freqSymbols.map(t => `${t}Hz`) as OctoFrequential<string>,
      null
    );
    drawOctoFrequentialTableLine(writeHead, writeHead.innerWidth);
    const processedWall = isAbsorption
      ? processedAbsorptionAssignments(wall, totalWallAreas[wIdx])
      : processedScatteringAssignments(wall, totalWallAreas[wIdx]);
    processedWall.forEach((assignment, aIdx) => {
      const mat = isAbsorption
        ? ABSORPTION_DATABASE.get(wall[aIdx].mat)
        : SCATTERING_DATABASE.get(wall[aIdx].matSc);
      if (mat) {
        drawHexaFrequentialTableRow(
          writeHead,
          writeHead.innerWidth,
          mat.values.map(val => val.toFixed(2)) as OctoFrequential<string>,
          `${
            (assignment.weight * Math.round(totalWallAreas[wIdx] * 100)) / 100
          }m² ${mat.title} (${mat.source}) [${isAbsorption ? "α" : "sc"}]`
        );
      }
    });
    drawOctoFrequentialTableLine(writeHead, writeHead.innerWidth);
    const totalWallAverage = wallAverage(processedWall);
    drawHexaFrequentialTableRow(
      writeHead,
      writeHead.innerWidth,
      totalWallAverage.map(val => val.toFixed(2)) as OctoFrequential<string>,
      `${totalWallAreas[wIdx]}m² total wall area weighted average [${
        isAbsorption ? "α" : "sc"
      }]`
    );
    writeHead.goDown(writeHead.BASE_FONT_SIZE);
  });
}

function drawFurnitureDetails(writeHead: PDFWriteHead, calc: CalculationState) {
  drawHeading(writeHead, `Detailed Wall Furniture`, 2);

  WALL_TITLES.forEach((title, wIdx) => {
    const wallFurniture = calc.furniture.filter(f => f.position === wIdx);

    if (wallFurniture.length > 0) {
      if (wIdx === 3) {
        writeHead.nextPage();
        drawIBPLogo(writeHead);
      }

      drawWall(writeHead, wIdx, wIdx < 6); // Draw wall diagram only for walls

      drawHeading(
        writeHead,
        `${WALL_TITLES[wIdx]} (${WALL_SYMBOLS[wIdx]}) Furniture`,
        3
      );
      drawHexaFrequentialTableRow(
        writeHead,
        writeHead.innerWidth,
        freqSymbols.map(t => `${t}Hz`) as OctoFrequential<string>,
        null
      );
      drawOctoFrequentialTableLine(writeHead, writeHead.innerWidth);

      let totalValues = Array(8).fill(0);

      wallFurniture.forEach(furniture => {
        const mat = FURNITURE_DATABASE.get(furniture.uuid);
        if (mat) {
          const values = mat.values.map(val =>
            val.toFixed(2)
          ) as OctoFrequential<string>;
          drawHexaFrequentialTableRow(
            writeHead,
            writeHead.innerWidth,
            values,
            `${furniture.quantity} - ${mat.title} [m²]`
          );

          mat.values.forEach((value, index) => {
            totalValues[index] += value * furniture.quantity;
          });
        }
      });
      drawOctoFrequentialTableLine(writeHead, writeHead.innerWidth);

      const summedValues = totalValues.map(value =>
        value.toFixed(2)
      ) as OctoFrequential<string>;
      drawHexaFrequentialTableRow(
        writeHead,
        writeHead.innerWidth,
        summedValues,
        `Sum for all the items [m²]`
      );
    }
  });
}

export async function downloadPDFReport(pdfDoc: PDFDocument) {
  const pdfData = await pdfDoc.save();
  downloadBlob(pdfData, store.roomName + ".pdf", "application/pdf");
}

export function createIframeDataUri(pdfDoc: PDFDocument): Promise<string> {
  return pdfDoc.saveAsBase64({ dataUri: true });
}
