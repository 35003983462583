

































import {
  createIframeDataUri,
  createPDFReport,
  createSimplePDFReport,
  downloadPDFReport
} from "@/helper/pdf-export/pdf-export";

import { Component, Vue, Watch } from "vue-property-decorator";
import { store } from "@/state/state";
import { PDFDocument } from "pdf-lib";

@Component({ components: {} })
export default class Export extends Vue {
  private pdfReport: PDFDocument | null = null;
  private iframeDataUri: string | null = null;

  mounted() {
    store.simulatedButtonPressed = true;
  }
  @Watch("store", { deep: true, immediate: true })
  async onResultChange() {
    if (store.result === null) {
      return;
    }
    this.pdfReport = store.detailedReport
      ? await createPDFReport(store.calc, store.result)
      : await createSimplePDFReport(store.calc, store.result);
    this.iframeDataUri = await createIframeDataUri(this.pdfReport);
  }
  get hotreloadHack() {
    this.onResultChange();
    return true;
  }
  downloadPDF() {
    this.pdfReport && downloadPDFReport(this.pdfReport);
  }
}
