import { freqSymbols } from "@/calc/acoustic-constants";
import { RTDeviationRule } from "@/calc/din-requirements";
import { OctoFrequential, SixDirectional } from "@/calc/room";
import { store } from "@/state/state";
import { degrees, grayscale, rgb } from "pdf-lib";
import {
  BULLET_STYLES,
  calculateAxisMarks,
  DIN_TARGET_LEGEND_ITEMS,
  DrawingStyle,
  DRAWING_STYLES,
  getDINCorridorPath,
  getDINTargetRTPath,
  getLegendHeight,
  getLegendPositions,
  //getMultiFreqLine,
  getMultiFreqLineHexa,
  gridXPath,
  gridYPath,
  LegendItem,
  LEGEND_ICON_OFFSET,
  OctoValuePlot,
  svgPathFromPoints
} from "../diagram";
import { PDFWriteHead } from "./pdf-write-head";
import {
  xAlignCenter,
  yAlignTop,
  xAlignRight,
  yAlignMiddle,
  yAlignBottom
} from "./pdf-text-align";

export function drawOctoFrequentialDiagram(
  writeHead: PDFWriteHead,
  width: number,
  octoFreqPlots: OctoValuePlot[],
  yMax: number,
  dinTargetRT: number | null,
  dinCorridor: OctoFrequential<RTDeviationRule> | null,
  yLabel: string
) {
  /** Finds highest value for y-axis and scales diagram */
  const allDecayTimes = [
    ...(octoFreqPlots[0].values || []),
    ...(octoFreqPlots[1].values || [])
  ];

  const slices = [...allDecayTimes.slice(1, 7), ...allDecayTimes.slice(9, 15)];
  const highestYValue = Math.max(...slices);

  const thresholds = [1, 2, 5, 10, 20, 50, 100, 150, 200];
  const scaledMax =
    thresholds.find(threshold => highestYValue < threshold) || 200;

  const plotCanvas = {
    xMin: 0,
    xMax: 5, // 7 = OctoFrequential
    yMin: 0,
    yMax: scaledMax,
    width: width - 80,
    height: 200,
    margin: 40,
    xRes: 1,
    yRes: scaledMax / 5
  };

  const originalX = writeHead.currentPage.getX() + 10;
  const originalY = writeHead.currentPage.getY() + plotCanvas.margin / 2;

  const xScale = plotCanvas.width / (plotCanvas.xMax - plotCanvas.xMin);
  //const yScale = plotCanvas.height / (plotCanvas.yMax - plotCanvas.yMin);
  const projectX = (x: number) => plotCanvas.margin + x * xScale;
  const projectY = (y: number) =>
    plotCanvas.margin +
    plotCanvas.height -
    (y / plotCanvas.yMax) * plotCanvas.height;

  const xAxisMarks = calculateAxisMarks(
    plotCanvas.xMin,
    plotCanvas.xMax,
    plotCanvas.xRes
  );
  const yAxisMarks = calculateAxisMarks(
    plotCanvas.yMin,
    plotCanvas.yMax,
    plotCanvas.yRes * 0.5
  );

  // Grid:
  const xGrid = gridXPath(
    xAxisMarks,
    plotCanvas.yMin,
    plotCanvas.yMax,
    projectX,
    projectY
  );
  const yGrid = gridYPath(
    yAxisMarks,
    plotCanvas.xMin,
    plotCanvas.xMax,
    projectX,
    projectY
  );
  writeHead.currentPage.drawSvgPath(xGrid, {
    x: originalX,
    y: originalY,
    borderWidth: 1,
    color: grayscale(0)
  });
  writeHead.currentPage.drawSvgPath(yGrid, {
    x: originalX,
    y: originalY,
    borderWidth: 1,
    color: grayscale(0)
  });

  // Axis marks
  const labelSize = 10;
  xAxisMarks.slice(0, 6).forEach(xVal => {
    const text = freqSymbols[xVal + 1];
    writeHead.currentPage.drawText(text, {
      x:
        originalX +
        projectX(xVal) +
        xAlignCenter(writeHead.normalFont, labelSize, text),
      y:
        originalY +
        yAlignTop(writeHead.normalFont, labelSize) -
        projectY(0) -
        labelSize / 2,
      font: writeHead.normalFont,
      size: labelSize,
      color: grayscale(0),
      lineHeight: labelSize
    });
  });
  yAxisMarks.forEach(yVal => {
    const text = yVal.toFixed(1);
    writeHead.currentPage.drawText(text, {
      x:
        originalX +
        projectX(0) +
        xAlignRight(writeHead.normalFont, labelSize, text) -
        labelSize / 2,
      y:
        originalY -
        projectY(yVal) +
        yAlignMiddle(writeHead.normalFont, labelSize),
      font: writeHead.normalFont,
      size: labelSize,
      color: grayscale(0),
      lineHeight: labelSize
    });
  });

  // Axis labels:
  const yAxisLabel = yLabel;
  const axisLabelSize = 16;
  writeHead.currentPage.drawText(yAxisLabel, {
    x: originalX - yAlignTop(writeHead.normalFont, axisLabelSize) - 7,
    y:
      originalY -
      plotCanvas.margin -
      plotCanvas.height / 2 +
      xAlignCenter(writeHead.normalFont, axisLabelSize, yAxisLabel),
    font: writeHead.normalFont,
    size: axisLabelSize,
    color: grayscale(0),
    lineHeight: axisLabelSize,
    rotate: degrees(90)
  });

  const xAxisLabel = "Frequency [Hz]";
  writeHead.currentPage.drawText(xAxisLabel, {
    x:
      plotCanvas.margin +
      originalX +
      xAlignCenter(writeHead.normalFont, axisLabelSize, xAxisLabel) +
      plotCanvas.width / 2,
    y:
      originalY -
      plotCanvas.margin -
      plotCanvas.height -
      plotCanvas.margin +
      yAlignBottom(),
    font: writeHead.normalFont,
    size: axisLabelSize,
    color: grayscale(0),
    lineHeight: axisLabelSize
  });

  const isASR = store.isASR;
  const slicedCorridor = dinCorridor
    ? isASR
      ? dinCorridor.slice(2, 6) // ASR: Frequenzbereich von 250 Hz bis 2 kHz
      : dinCorridor.slice(1, 7)
    : [];
  // Din Corridor:
  if (dinTargetRT !== null && dinCorridor !== null) {
    const offsetX = isASR ? 75 : 0; // Offset für ASR Szenario
    const corridorSVGPath = getDINCorridorPath(
      dinTargetRT,
      slicedCorridor as SixDirectional<RTDeviationRule>,
      projectX,
      projectY
    );
    writeHead.currentPage.drawSvgPath(corridorSVGPath, {
      x: originalX + offsetX,
      y: originalY,
      color: DRAWING_STYLES.corridor.color
        ? hexToPDFColor(DRAWING_STYLES.corridor.color)
        : undefined,
      opacity: DRAWING_STYLES.corridor.fillOpacity
    });
    const targetSVGPath = getDINTargetRTPath(dinTargetRT, projectX, projectY);
    writeHead.currentPage.drawSvgPath(targetSVGPath, {
      x: originalX + offsetX,
      y: originalY,
      borderColor:
        DRAWING_STYLES.target.stroked && DRAWING_STYLES.target.color
          ? hexToPDFColor(DRAWING_STYLES.target.color)
          : undefined,
      borderWidth: DRAWING_STYLES.target.strokeWidth,
      borderDashArray: DRAWING_STYLES.target.dasharray
    });
  }

  // Plot multifreq polylines:
  octoFreqPlots.forEach(plot => {
    const points = getMultiFreqLineHexa(
      plot.values.slice(1, 7) as any,
      projectX,
      projectY
    );
    const svgPath = svgPathFromPoints(
      points as SixDirectional<[number, number]>
    );
    writeHead.currentPage.drawSvgPath(svgPath, {
      x: originalX,
      y: originalY,
      borderWidth: 2
    });
    const bulletStyle =
      (plot.bullet && BULLET_STYLES[plot.bullet]) || BULLET_STYLES.circle;
    points.forEach(p =>
      writeHead.currentPage.drawSvgPath(bulletStyle.bullet, {
        x: originalX + p[0],
        y: originalY - p[1],
        borderWidth: 0,
        color: grayscale(0)
      })
    );
  });

  // Legend:
  const legendItems: LegendItem[] = [];
  if (dinCorridor !== null && dinTargetRT !== null) {
    legendItems.push(...DIN_TARGET_LEGEND_ITEMS);
  }
  legendItems.push(...(octoFreqPlots as LegendItem[]));
  const legendPositions: [number, number][] = getLegendPositions(
    legendItems.length,
    plotCanvas.width,
    2
  );
  const showLegend = true;
  const legendHeight: number = showLegend
    ? getLegendHeight(legendItems.length, 2)
    : 0;
  const legendX = originalX + plotCanvas.margin;
  const legendY =
    originalY - plotCanvas.margin - plotCanvas.height - plotCanvas.margin;
  legendItems.forEach((lItem, lIdx) => {
    const lPos = legendPositions[lIdx];
    if (lItem.bullet) {
      const bulletPath = BULLET_STYLES[lItem.bullet || "circle"].bullet;
      writeHead.currentPage.drawSvgPath(bulletPath, {
        x: legendX + lPos[0],
        y: legendY - lPos[1],
        borderWidth: 1,
        color: grayscale(0)
      });
    }
    const bgStyle: DrawingStyle = lItem.drawingStyle
      ? DRAWING_STYLES[lItem.drawingStyle]
      : DRAWING_STYLES.line;
    writeHead.currentPage.drawSvgPath(bgStyle.path, {
      x: legendX + lPos[0],
      y: legendY - lPos[1],
      borderWidth: bgStyle.strokeWidth,
      borderColor:
        bgStyle.color && bgStyle.stroked
          ? hexToPDFColor(bgStyle.color)
          : undefined,
      color:
        bgStyle.color && bgStyle.filled
          ? hexToPDFColor(bgStyle.color)
          : undefined,
      opacity: bgStyle.fillOpacity,
      borderDashArray: bgStyle.dasharray
    });
    const labelXOffset: number = LEGEND_ICON_OFFSET * 1.5;
    writeHead.currentPage.drawText(lItem.label, {
      x: legendX + lPos[0] + labelXOffset,
      y:
        legendY -
        lPos[1] -
        0.5 *
          writeHead.normalFont.heightAtSize(labelSize, { descender: false }),
      size: labelSize,
      font: writeHead.normalFont
    });
  });

  // Calculate total height of diagram and scroll down the page:
  const totalHeight =
    plotCanvas.margin / 2 +
    plotCanvas.height +
    plotCanvas.margin +
    plotCanvas.margin / 2 +
    legendHeight +
    0;
  writeHead.goDown(totalHeight);
}

/**
 * Converts a 6-digit hex color string to a valid PDF-Lib.js color
 * If r, g, and b component are the same, it returns a grayscale color for better printing
 * @param hex e.g. "#000000" "#FFFFFF"
 * */
export function hexToPDFColor(hex: string) {
  if (hex[0] !== "#" || hex.length != 7) {
    throw Error("string is no valid hex color like e.g. `#FF0000`");
  }
  const r = parseInt(hex.substr(1, 2), 16) / 255;
  const g = parseInt(hex.substr(3, 2), 16) / 255;
  const b = parseInt(hex.substr(5, 2), 16) / 255;
  if (r === g && g === b) {
    return grayscale(r);
  } else {
    return rgb(r, g, b);
  }
}
